import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import resources from '../../components/acknowledgment/Acknowledgment.resources.json';
import { AcknowledgmentForm } from '../../components/acknowledgment/AcknowledgmentForm';
import { Project } from '../../components/acknowledgment/models/Models';
import service from '../../components/acknowledgment/Service';
import ImageViewer from '../../components/contractViewer/ImageViewer';
import { State } from '../../components/shared/models/State';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Background } from '../../components/stack/models/Background';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import { SupportBox } from '../../components/support/SupportBox';
import UseIsMobile from '../../utils/useIsMobile';

export const AcknowledgmentFormPage = () => {
    const params = useParams();
    const navigator = useNavigate();

    const [project, setProject] = useState<Project>();
    const [state, setState] = useState<State>(State.LOADING);

    useEffect(() => {
        (async () => {
            await service
                .getProject(params.token)
                .then((response) => {
                    setProject(response);
                    setState(State.OK);
                })
                .catch(() => {
                    navigator('/notLoaded', { state: params.token });
                });
        })();
    }, [params.token, navigator]);

    return (
        <>
            {UseIsMobile() && (
                <>
                    <div className="container">
                        {(state === State.OK || state === State.LOADING) && (
                            <>
                                <h4>
                                    {resources.acknowledgement.project} {project?.Transaction}
                                </h4>
                                <ImageViewer token={params.token} />
                                <AcknowledgmentForm token={params.token} />
                            </>
                        )}
                    </div>
                </>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={
                        <SplitLayout
                            left={
                                <>
                                    {(state === State.OK || state === State.LOADING) && (
                                        <div>
                                            <h4>
                                                {resources.acknowledgement.project} {project?.Transaction}
                                            </h4>
                                            <ImageViewer token={params.token} />
                                        </div>
                                    )}
                                </>
                            }
                            right={
                                <div className="pe-4 me-4" style={{ borderLeft: '1px solid lightgrey' }}>
                                    <AcknowledgmentForm token={params.token} />
                                </div>
                            }
                        ></SplitLayout>
                    }
                    right={
                        <Stack orientation={Orientation.VERTICAL} background={Background.GREY}>
                            <SupportBox />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
