import { useNavigate } from 'react-router';
import { ErrorBox } from '../../components/errors/ErrorBox';
import resources from '../../components/errors/Messages.resources.json';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Background } from '../../components/stack/models/Background';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import { SupportBox } from '../../components/support/SupportBox';
import UseIsMobile from '../../utils/useIsMobile';

export const NotFoundPage = () => {
    const navigator = useNavigate();

    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    <ErrorBox code={404} />
                    <div className="helpButton text-center">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => navigator('/')}>
                            {resources.GoTo}
                        </button>
                    </div>
                </div>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={
                        <>
                            <ErrorBox code={404} />
                            <div className="helpButton text-center">
                                <button type="button" className="btn btn-secondary me-2" onClick={() => navigator('/')}>
                                    {resources.GoTo}
                                </button>
                            </div>
                        </>
                    }
                    right={
                        <Stack orientation={Orientation.VERTICAL} background={Background.WHITE}>
                            <SupportBox />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
