import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ErrorBox } from '../errors/ErrorBox';
import { FeedbackForm } from '../feedback/FeedbackForm';
import { State } from '../shared/models/State';
import resources from './Request.resources.json';
import './RequestConfirmation.css';

export const RequestConfirmation = ({ requestID }: { requestID?: string }) => {
    const navigator = useNavigate();
    const location = useLocation();
    const [trackingNumber] = useState(requestID ?? (location.state as string | null | undefined));
    const [state] = useState((location.state as string | null | undefined) === null ? State.FAILED : State.OK);

    return (
        <>
            {state === State.OK && (
                <>
                    <FeedbackForm Section="EXTERNALREQUEST" RequestID={trackingNumber} />
                    <div className="whiteback requestConfirmationPage text-center">
                        <div className="row"></div>
                        <div className="row pb-4">
                            <h4>{resources.requestConfirmation.title}</h4>
                        </div>
                        <div className="row pb-4">
                            <p>
                                {resources.requestConfirmation.trackingNumber} {trackingNumber}
                            </p>
                        </div>
                        <div className="row pb-4">
                            <p>{resources.requestConfirmation.enquiries}</p>
                        </div>
                        <div className="helpButton text-center">
                            <button type="button" className="btn btn-secondary me-2" onClick={() => navigator('/')}>
                                {resources.requestConfirmation.goToHome}
                            </button>
                        </div>
                    </div>
                </>
            )}
            {state === State.FAILED && (
                <>
                    <ErrorBox code={404} />
                    <div className="helpButton text-center">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => navigator('/')}>
                            {resources.requestConfirmation.goToHome}
                        </button>
                    </div>
                </>
            )}
        </>
    );
};
