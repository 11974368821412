import resources from './Resolve.resources.json';

export const UnauthorizedBox = () => {
    return (
        <>
            <div className="whiteback requestConfirmationPage text-center">
                <div className="row p-4 m-4">
                    <svg width="121" height="120" viewBox="0 0 121 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_4285_10918)">
                            <path
                                d="M60.5 105C66.0228 105 70.5 100.523 70.5 95C70.5 89.4772 66.0228 85 60.5 85C54.9772 85 50.5 89.4772 50.5 95C50.5 100.523 54.9772 105 60.5 105Z"
                                fill="#B1B1B1"
                            />
                            <path
                                d="M60.5 15C55 15 50.5 19.5 50.5 25V65C50.5 70.5 55 75 60.5 75C66 75 70.5 70.5 70.5 65V25C70.5 19.5 66 15 60.5 15Z"
                                fill="#B1B1B1"
                            />
                        </g>
                    </svg>
                </div>
                <div className="row pb-4">
                    <p>{resources.unauthorized.title}</p>
                    <p>{resources.unauthorized.subTitle}</p>
                </div>
                <div className="row pb-4">
                    <p>{resources.unauthorized.nextSteps}</p>
                </div>
            </div>
        </>
    );
};
