import parser from 'html-react-parser';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ErrorBox } from '../errors/ErrorBox';
import { FeedbackForm } from '../feedback/FeedbackForm';
import { State } from '../shared/models/State';
import resources from './Acknowledgment.resources.json';
import './AcknowledgmentConfirmation.css';

export const AcknowledgmentConfirmation = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const [state] = useState(location.state ?? State.FAILED);

    return (
        <>
            {state === State.SUBMITTED && (
                <>
                    <FeedbackForm Section="EXTERNALACKNOWLEDGEMENT" />
                    <div className="whiteback requestConfirmationPage text-center">
                        <div className="row"></div>
                        <div className="row pb-4">
                            <h4>{resources.acknowledgmentConfirmation.title}</h4>
                        </div>
                        <div className="row pb-4">
                            <p>{parser(`${resources.acknowledgmentConfirmation.enquiries}`)}</p>
                        </div>
                        <div className="row pb-4">
                            <p>{parser(`${resources.acknowledgmentConfirmation.finally}`)}</p>
                        </div>
                    </div>
                </>
            )}
            {state === State.FAILED && (
                <>
                    <ErrorBox code={404} />
                    <div className="helpButton text-center">
                        <button type="button" className="btn btn-secondary me-2" onClick={() => navigator('/')}>
                            {resources.acknowledgmentConfirmation.goToHome}
                        </button>
                    </div>
                </>
            )}
        </>
    );
};
