import { AccordionViewer } from '../../components/documentViewer/AccordionViewer';
import { Document } from '../../components/documentViewer/models/Document';
import { PageNavigator } from '../../components/documentViewer/PageNavigator';
import { PageViewer } from '../../components/documentViewer/PageViewer';
import { RequestBox } from '../../components/request/RequestBox';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import UseIsMobile from '../../utils/useIsMobile';
import document from './Document.json';

export const TermsAndConditionsPage = () => {
    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    <AccordionViewer data={document as Document} />
                </div>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={<PageViewer data={document as Document} />}
                    right={
                        <Stack orientation={Orientation.VERTICAL}>
                            <RequestBox />
                            <PageNavigator data={document as Document} />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
