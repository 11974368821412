import './ErrorBox.css';
import resources from './Errors.resources.json';
import { Error } from './models/Models';

export const ErrorBox = ({ code, showBorder }: { code: number; showBorder?: boolean }) => {
    const error = (resources as Error[])?.find((id) => id.Code === code);

    return (
        <div className="text-center pb-4">
            <div className={(showBorder ?? false) ? 'box' : 'pt-4'}>
                <h4 className="title pt-4">{error?.Title}</h4>
                <p>{error?.Description}</p>
            </div>
        </div>
    );
};
