import axios from 'axios';
import config from '../../utils/config';
import { Image } from './models/Image';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const Service = {
    getImages: async (token: string | undefined) => {
        const url = `${config.proxyAPI}/templates/${token}`;
        return axios
            .get<Image[]>(url)
            .then((response) => {
                return response.data;
            })
            .catch((error: unknown) => {
                throw error;
            });
    },
};
export default Service;
