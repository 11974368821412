import { Link } from 'react-router-dom';
import UseIsMobile from '../../utils/useIsMobile';
import './Bar.css';
import Logo from './images/bcg-logo-green.png';
import resources from './Navigation.resources.json';

import { Nav, Navbar } from 'react-bootstrap';
import { NavIcon } from './NavIcon';

export const Bar = () => {
    return (
        <>
            <Navbar className="navbar navbar-expand-custom bg-white" id="navbar1" expand="lg">
                <Navbar.Brand href="#home">
                    <Link className="navbar-brand text-uppercase" to="/">
                        <img src={Logo} alt="BCG Logo" width="50" height="25" />
                        <span className="mx-3">|</span>
                        {resources.navigation.title}
                    </Link>
                </Navbar.Brand>
                <div className="hamburgerMenu">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </div>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-between">
                    <Nav className="navbar-nav ml-auto"></Nav>
                    <Nav className="d-flex">
                        <Nav.Link className="rightLinks hamburgerLinks" href="/nrl/frequentlyAskedQuestions">
                            <NavIcon type="FAQ" />
                            {resources.navigation.frequentlyAskedQuestions}
                        </Nav.Link>
                        <Nav.Link className="rightLinks hamburgerLinks" href="/nrl/termsOfUse">
                            <NavIcon type="TERMSOFUSE" />
                            {resources.navigation.termsOfUse}
                        </Nav.Link>
                        <Nav.Link className="rightLinks hamburgerLinks" href="/nrl/privacyPolicy">
                            <NavIcon type="PRIVACY" />
                            {resources.navigation.privacyPolicy}
                        </Nav.Link>
                        {UseIsMobile() && (
                            <Nav.Link className="rightLinks hamburgerLinks" href="/nrl/support">
                                <NavIcon type="HELP" />
                                {resources.navigation.help}
                            </Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};
