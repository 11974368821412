import { Outlet } from 'react-router';
import { Bar } from '../../components/navigation/Bar';
import { ScrollToTop } from './ScrollToTop';

export const Layout = () => {
    return (
        <>
            <ScrollToTop />
            <Bar />
            <main>
                <Outlet />
            </main>
        </>
    );
};
