import axios from 'axios';
import axiosRetry from 'axios-retry';
import config from '../../utils/config';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axiosRetry(axios);

const Service = {
    approve: async (token: string | null | undefined) => {
        if (token == null || token === undefined) {
            return false;
        }
        const url = `${config.proxyAPI}/requests/approve/${token}`;
        return axios.get<any>(url).catch((error: unknown) => {
            throw error;
        });
    },

    decline: async (token: string | null | undefined) => {
        if (token == null || token === undefined) {
            return false;
        }
        const url = `${config.proxyAPI}/requests/decline/${token}`;
        return axios.get(url).catch((error: unknown) => {
            throw error;
        });
    },
};

export default Service;
