import { AcknowledgmentConfirmation } from '../../components/acknowledgment/AcknowledgmentConfirmation';
import UseIsMobile from '../../utils/useIsMobile';

export const AcknowledgmentConfirmationPage = () => {
    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    <AcknowledgmentConfirmation />
                </div>
            )}
            {!UseIsMobile() && <AcknowledgmentConfirmation />}
        </>
    );
};
