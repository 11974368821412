import axios from 'axios';
import axiosRetry from 'axios-retry';
import { v4 as uuidv4 } from 'uuid';

axiosRetry(axios);
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

axios.interceptors.request.use((x) => {
    if (!x.headers['x-correlation-id']) {
        x.headers['x-correlation-id'] = uuidv4();
    }

    return x;
});
