import parser from 'html-react-parser';
import { Document } from './models/Document';
import './PageViewer.css';

export const PageViewer = ({ data }: { data: Document | undefined }) => {
    return (
        <>
            {data && (
                <>
                    <section id="PAGE">
                        <h3 className="pageHeading">{data?.Title}</h3>
                        {data?.SubTitle && <small className="text-sm">{data?.SubTitle}</small>}
                        {data?.Description && <h4 className="pb-2 pt-4">{data?.Description}</h4>}
                        {data?.Alternate && <small className="text-sm">{parser(`${data?.Alternate}`)}</small>}
                        {data?.Disclaimer && <p className="pb-2 pt-4">{data?.Disclaimer}</p>}
                    </section>

                    {data?.Sections.map((section) => (
                        <section id={'SECTION' + section.Key} key={section.Key}>
                            <h4 className="policyTitle">{section.Title}</h4>
                            {section.Paragraphs?.map((paragraph) => (
                                <p className="paragraph" key={paragraph.Key}>
                                    {parser(`${paragraph?.Content}`)}
                                </p>
                            ))}
                        </section>
                    ))}
                </>
            )}
        </>
    );
};
