import { Background } from './models/Background';
import { Orientation } from './models/Orientation';

export const Stack = ({
    orientation,
    background,
    children,
}: {
    orientation?: Orientation;
    background?: Background;
    children: any;
}) => {
    return (
        <>
            <div
                className={'' + (orientation ?? 'vstack') + ' '}
                style={{
                    background: background ?? Background.WHITE,
                    minHeight: '93.5vh',
                    height: '100vh',
                    width: '100%',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                    paddingTop: '3em',
                }}
            >
                {children}
            </div>
        </>
    );
};
