import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ErrorBox } from './ErrorBox';
import './ErrorPopUp.css';

export const ErrorPopUp = ({ code, onClosed }: { code: number; onClosed?: any | undefined }) => {
    const onClose = () => {
        setShowPopUp(false);
        onClosed();
    };

    const [showPopUp, setShowPopUp] = useState(true);

    return (
        <>
            {showPopUp && (
                <Modal className="modal" show={showPopUp}>
                    <Modal.Body>
                        <ErrorBox code={code} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary me-1" onClick={onClose}>
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};
