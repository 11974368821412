import './SplitLayout.css';

export function SplitLayout(props: any) {
    return (
        <div className="main-panel container">
            <div className="leftPanel container">{props.left}</div>
            <div className="rightPanel sticky-top">{props.right}</div>
        </div>
    );
}
