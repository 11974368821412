import axios from 'axios';
import config from '../../utils/config';
import { Feedback } from './Models/Feedback';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

const endpoint = '/feedback';

export const service = {
    sendFeedback: async (feedback: Feedback) => {
        const url = `${config.proxyAPI}${endpoint}`;
        return axios
            .post(url, feedback)
            .then((response) => {
                return response.data;
            })
            .catch((err: any) => {
                throw err;
            });
    },
};
