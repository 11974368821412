import resources from './Resolve.resources.json';

export const NoChangeBox = () => {
    return (
        <>
            <div className="whiteback requestConfirmationPage text-center">
                <div className="row p-4 m-4">
                    <svg width="141" height="105" viewBox="0 0 141 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M43.5007 84.75L14.584 55.8333C11.334 52.5833 6.08398 52.5833 2.83398 55.8333C-0.416016 59.0833 -0.416016 64.3333 2.83398 67.5833L37.6673 102.417C40.9173 105.667 46.1673 105.667 49.4173 102.417L137.584 14.25C140.834 11 140.834 5.75 137.584 2.5C134.334 -0.75 129.084 -0.75 125.834 2.5L43.5007 84.75Z"
                            fill="#B1B1B1"
                        />
                    </svg>
                </div>
                <div className="row pb-4">
                    <p>{resources.noChange.title}</p>
                    <p>{resources.noChange.subTitle}</p>
                </div>
                <div className="row pb-4">
                    <p>{resources.noChange.nextSteps}</p>
                </div>
            </div>
        </>
    );
};
