const envSettings = window as any;

const config = {
    proxyAPI: envSettings.VAULT_PROXYAPI,
    apiKey: envSettings.VAULT_API_KEY,
    captchaKey: envSettings.VAULT_CAPTCHA_KEY,
    rumEnvironment: envSettings.VAULT_RUM_ENVIRONMENT,
    rumApplicationID: envSettings.VAULT_RUM_APPLICATION_ID,
    rumClientToken: envSettings.VAULT_RUM_CLIENT_TOKEN,
    rumService: envSettings.VAULT_RUM_SERVICE,
};

export default config;
