import { Route, Routes } from 'react-router-dom';
import { AcknowledgmentConfirmationPage } from '../pages/acknowledgement/AcknowledgmentConfirmationPage';
import { AcknowledgmentFormPage } from '../pages/acknowledgement/AcknowledgmentFormPage';
import { FrequentlyAskedQuestionsPage } from '../pages/frequentlyAskedQuestions/FrequentlyAskedQuestionsPage';
import { Layout } from '../pages/layout/Layout';
import { NotFoundPage } from '../pages/notFound/NotFoundPage';
import { NotLoadedPage } from '../pages/notFound/NotLoadedPage';
import { PrivacyPolicyPage } from '../pages/privacyPolicy/PrivacyPolicyPage';
import { RequestConfirmationPage } from '../pages/request/RequestConfirmationPage';
import { RequestFormPage } from '../pages/request/RequestFormPage';
import { RequestApprovePage } from '../pages/resolveRequest/RequestApprovePage';
import { RequestDeclinePage } from '../pages/resolveRequest/RequestDeclinePage';
import { SupportConfirmationPage } from '../pages/support/SupportConfirmationPage';
import { SupportFormPage } from '../pages/support/SupportFormPage';
import { TermsAndConditionsPage } from '../pages/termsAndConditions/TermsAndConditionsPage';

function RouteHandler() {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/">
                    <Route index element={<RequestFormPage />} />
                    <Route path="privacyPolicy" element={<PrivacyPolicyPage />}></Route>
                    <Route path="termsOfUse" element={<TermsAndConditionsPage />}></Route>
                    <Route path="frequentlyAskedQuestions" element={<FrequentlyAskedQuestionsPage />}></Route>
                    <Route path="support" element={<SupportFormPage />}></Route>
                    <Route path="supportConfirmation" element={<SupportConfirmationPage />}></Route>
                    <Route path="request" element={<RequestFormPage />}></Route>
                    <Route path="requestConfirmation" element={<RequestConfirmationPage />}></Route>
                    <Route path="acknowledgment/:token" element={<AcknowledgmentFormPage />}></Route>
                    <Route path="acknowledgement/:token" element={<AcknowledgmentFormPage />}></Route>
                    <Route path="acknowledgmentConfirmation" element={<AcknowledgmentConfirmationPage />}></Route>
                    <Route path="notLoaded" element={<NotLoadedPage />}></Route>
                    <Route path="approve/:token" element={<RequestApprovePage />}></Route>
                    <Route path="decline/:token" element={<RequestDeclinePage />}></Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>
            </Route>
        </Routes>
    );
}

export default RouteHandler;
