import { RequestBox } from '../../components/request/RequestBox';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import { SupportForm } from '../../components/support/SupportForm';
import UseIsMobile from '../../utils/useIsMobile';

export const SupportFormPage = () => {
    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    <SupportForm />
                </div>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={<SupportForm />}
                    right={
                        <Stack orientation={Orientation.VERTICAL}>
                            <RequestBox />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
