import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { State } from '../../components/shared/models/State';

import { ErrorBox } from '../../components/errors/ErrorBox';
import { Loading } from '../../components/loading/Loading';
import { DeclinedBox } from '../../components/resolveRequest/DeclinedBox';
import { NoChangeBox } from '../../components/resolveRequest/NoChangeBox';
import { UnauthorizedBox } from '../../components/resolveRequest/UnauthorizedBox';
import service from './Service';

export const RequestDeclinePage = () => {
    const params = useParams();

    const [state, setState] = useState<State>(State.LOADING);

    useEffect(() => {
        (async () => {
            if (params.token === null || params.token === undefined || params.token.length < 20) {
                setState(State.UNAUTHORIZED);
            } else {
                await service
                    .decline(params.token)
                    .then(() => {
                        setState(State.OK);
                    })
                    .catch((error) => {
                        switch (error.status) {
                            case 304:
                                setState(State.NOCHANGE);
                                break;
                            case 401:
                                setState(State.UNAUTHORIZED);
                                break;
                            case 404:
                                setState(State.UNAUTHORIZED);
                                break;
                            default:
                                setState(State.FAILED);
                                break;
                        }
                    });
            }
        })();
    }, [params.token]);

    return (
        <div>
            {state === State.LOADING && <Loading></Loading>}
            {state === State.OK && <DeclinedBox />}
            {state === State.UNAUTHORIZED && <UnauthorizedBox />}
            {state === State.FAILED && <ErrorBox code={500} />}
            {state === State.NOCHANGE && <NoChangeBox />}
        </div>
    );
};
