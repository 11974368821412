import axios from 'axios';
import config from '../../utils/config';
import { Document } from '../documentViewer/models/Document';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
const endpoint = '/frequentlyAskedQuestions';

const service = {
    getFrequentlyAskedQuestions: async () => {
        const url = `${config.proxyAPI}${endpoint}`;
        return axios
            .get<Document>(url)
            .then((response) => {
                return response.data;
            })
            .catch((error: unknown) => {
                throw error;
            });
    },
};

export default service;
