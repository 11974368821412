import ScrollSpy from 'react-scrollspy-navigation';
import { Document } from './models/Document';
import './PageNavigator.css';

export const PageNavigator = ({ data }: { data: Document | undefined }) => {
    function scrollToElement(element: HTMLElement) {
        element.style.scrollMargin = '100px';
        element.setAttribute('active', 'true');
        element.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
    }

    const onLinkClick = (e: any, next: any, container: any) => {
        const hash = new URL(e.target.href).hash;
        const element = document.getElementById(hash.substring(1));
        if (element) {
            scrollToElement(element);
        }
        next();
    };

    return (
        <>
            {data && (
                <div className="side-nav">
                    <div id="side-nav" className="index-list">
                        <ScrollSpy activeClass={'index-active'} onClickEach={onLinkClick}>
                            <a key="PAGE" className="index" href="#PAGE">
                                {data?.Title}
                            </a>
                            {data?.Sections.map((section) => (
                                <a key={section.Key} className="index" href={'#SECTION' + section.Key}>
                                    {section.Title}
                                </a>
                            ))}
                        </ScrollSpy>
                    </div>
                </div>
            )}
        </>
    );
};
