import { useEffect, useState } from 'react';
import { AccordionViewer } from '../../components/documentViewer/AccordionViewer';
import { Document } from '../../components/documentViewer/models/Document';
import { ErrorBox } from '../../components/errors/ErrorBox';
import resources from '../../components/frequentlyAskedQuestions/FrequentlyAskedQuestion.resources.json';
import service from '../../components/frequentlyAskedQuestions/Service';
import { RequestBox } from '../../components/request/RequestBox';
import { State } from '../../components/shared/models/State';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Background } from '../../components/stack/models/Background';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import UseIsMobile from '../../utils/useIsMobile';

export const FrequentlyAskedQuestionsPage = () => {
    const [document, setDocument] = useState<Document>();
    const [state, setState] = useState<State>(State.LOADING);

    useEffect(() => {
        (async () => {
            await service
                .getFrequentlyAskedQuestions()
                .then((response) => {
                    if (response) {
                        response.Title = resources.frequentlyAskedQuestion.title;
                        response.Disclaimer = resources.frequentlyAskedQuestion.subTitle;
                    }
                    setDocument(response);
                    setState(State.OK);
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        })();
    }, []);

    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    {state === State.OK && <AccordionViewer data={document as Document} />}
                    {state === State.FAILED && <ErrorBox code={500} />}
                </div>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={
                        <>
                            {state === State.OK && <AccordionViewer data={document as Document} />}
                            {state === State.FAILED && <ErrorBox code={500} />}
                        </>
                    }
                    right={
                        <Stack orientation={Orientation.VERTICAL} background={Background.WHITE}>
                            <RequestBox />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
