import parser from 'html-react-parser';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import config from '../../utils/config';
import UseIsMobile from '../../utils/useIsMobile';
import { ErrorPopUp } from '../errors/ErrorPopUp';
import { State } from '../shared/models/State';
import { ToolTipIcon } from '../shared/ToolTipIcon';
import { SpinnerButton } from '../spinnerButton/SpinnerButton';
import { SupportMail } from './models/SupportMail';
import service from './Service';
import resources from './Support.resources.json';

export const SupportForm = ({ testState }: { testState?: State }) => {
    const navigator = useNavigate();
    const recaptchaRef = React.createRef<ReCAPTCHA>();

    const [state, setState] = useState<State>(testState ?? State.OK);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SupportMail>({
        shouldUseNativeValidation: true,
        shouldFocusError: false,
    });

    const isFormInvalid = Object.keys(errors).length > 0;

    const onSubmit = async (data: SupportMail) => {
        if (recaptchaRef?.current != null) {
            const token = await recaptchaRef.current.executeAsync();
            const newRequest: SupportMail = {
                Captcha: token ?? '',
                FirstNames: data.FirstNames,
                LastNames: data.LastNames,
                LegalEntity: data.LegalEntity,
                EMail: data.EMail,
                ReferenceNumber: data.ReferenceNumber,
                Description: data.Description,
            };

            setState(State.SUBMITTING);

            await service
                .sendSupportMail(newRequest)
                .then(() => {
                    setState(State.SUBMITTED);
                    navigator('/supportConfirmation');
                })
                .catch(() => {
                    setState(State.FAILED);
                });
        }
    };

    const isNotEmpty = (value: any) => {
        return value.trim() !== '';
    };

    const isAccepted = (value: any) => {
        return value === true;
    };

    return (
        <>
            <div className="whiteback requestPage">
                {(state === State.OK || state === State.SUBMITTING || state === State.FAILED) && (
                    <>
                        <form onSubmit={handleSubmit(onSubmit)} className={isFormInvalid ? 'was-validated' : ''}>
                            <fieldset disabled={state === State.SUBMITTING}>
                                <h4>{resources.supportRequest.title}</h4>
                                <div className="request">
                                    <p>{resources.supportRequest.subTitle}</p>
                                    <p>{resources.supportRequest.formTitle}</p>
                                    <div className="form-group mb-3">
                                        <div className="row">
                                            <div className="col-md">
                                                <label className="form-label">
                                                    {resources.supportRequest.firstNames.label}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={resources.supportRequest.firstNames.placeHolder}
                                                    id="FirstNames"
                                                    {...register('FirstNames', {
                                                        required: resources.supportRequest.firstNames.required,
                                                        validate: {
                                                            isEmpty: (e) =>
                                                                isNotEmpty(e) ||
                                                                resources.supportRequest.firstNames.validation,
                                                        },
                                                    })}
                                                />
                                                <div className="invalid-feedback">
                                                    <span>{resources.supportRequest.firstNames.required}</span>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <label className="form-label">
                                                    {resources.supportRequest.lastNames.label}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={resources.supportRequest.lastNames.placeHolder}
                                                    id="LastNames"
                                                    {...register('LastNames', {
                                                        required: resources.supportRequest.lastNames.required,
                                                        validate: {
                                                            isEmpty: (e) =>
                                                                isNotEmpty(e) ||
                                                                resources.supportRequest.lastNames.validation,
                                                        },
                                                    })}
                                                />
                                                <div className="invalid-feedback">
                                                    <span>{resources.supportRequest.lastNames.required}</span>
                                                </div>
                                            </div>
                                            {/*  <div className="col-md">*/}
                                            {/*    <label className="form-label">{resources.supportRequest.legalEntity.label}</label>*/}
                                            {/*    <input className="form-control" type="text"*/}
                                            {/*      placeholder={resources.supportRequest.legalEntity.placeHolder} id="LastNames"*/}
                                            {/*      {...register('LegalEntity', {*/}
                                            {/*        required: resources.supportRequest.legalEntity.required,*/}
                                            {/*        validate: { isEmpty: e => isNotEmpty(e) || resources.supportRequest.legalEntity.validation }*/}
                                            {/*      })}*/}
                                            {/*    />*/}
                                            {/*    <div className="invalid-feedback"><span>{resources.supportRequest.legalEntity.required}</span>*/}
                                            {/*    </div>*/}
                                            {/*  </div>*/}
                                        </div>
                                        <div className="row">
                                            <div className="col-md">
                                                <label className="form-label">
                                                    {resources.supportRequest.eMail.label}
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={resources.supportRequest.eMail.placeHolder}
                                                    id="EMail"
                                                    {...register('EMail', {
                                                        required: resources.supportRequest.eMail.required,
                                                        pattern: {
                                                            value: /^\S+@\S+\.\S+$/,
                                                            message: resources.supportRequest.eMail.validation,
                                                        },
                                                    })}
                                                />
                                                {
                                                    <div className="invalid-feedback">
                                                        <span>{resources.supportRequest.eMail.required}</span>
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-md">
                                                <label className="form-label">
                                                    {resources.supportRequest.referenceNumber.label}
                                                    <ToolTipIcon
                                                        toolTip={resources.supportRequest.referenceNumber.tooltip}
                                                    />
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={resources.supportRequest.referenceNumber.placeHolder}
                                                    id="ReferenceNumber"
                                                    {...register('ReferenceNumber')}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md">
                                                <label className="form-label">
                                                    {resources.supportRequest.description.label}
                                                    <ToolTipIcon
                                                        toolTip={resources.supportRequest.description.tooltip}
                                                    />
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    rows={4}
                                                    placeholder={resources.supportRequest.description.placeHolder}
                                                    id="Description"
                                                    {...register('Description', {
                                                        required: resources.supportRequest.description.required,
                                                        validate: {
                                                            isEmpty: (e) =>
                                                                isNotEmpty(e) ||
                                                                resources.supportRequest.description.validation,
                                                        },
                                                    })}
                                                />
                                                <div className="invalid-feedback">
                                                    <span>{resources.supportRequest.description.required}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 form-check form-group mb-3">
                                            <input
                                                className={`form-check-input ${errors['IsAccepted'] ? 'is-invalid' : ''}`}
                                                defaultChecked={false}
                                                type="checkbox"
                                                id="IsAccepted"
                                                {...register('IsAccepted', {
                                                    validate: {
                                                        isAccepted: (e) =>
                                                            isAccepted(e) || resources.supportRequest.consent.required,
                                                    },
                                                })}
                                            />
                                            <label className="form-check-label">
                                                {parser(`${resources.supportRequest.consent.description}`)}
                                            </label>
                                            {errors['IsAccepted'] && (
                                                <div className="invalid-feedback">
                                                    <span>{resources.supportRequest.consent.required}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="row button-row">
                                        {UseIsMobile() && (
                                            <SpinnerButton type="submit" state={state}>
                                                {resources.supportRequest.submit.label}
                                            </SpinnerButton>
                                        )}
                                        <div className="col-md d-flex flex-row-reverse">
                                            {!UseIsMobile() && (
                                                <SpinnerButton type="submit" state={state}>
                                                    {resources.supportRequest.submit.label}
                                                </SpinnerButton>
                                            )}
                                            <ReCAPTCHA
                                                badge="bottomleft"
                                                ref={recaptchaRef}
                                                size="invisible"
                                                sitekey={config.captchaKey}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                        {state === State.FAILED && <ErrorPopUp code={500} onClosed={() => setState(State.OK)} />}
                    </>
                )}
            </div>
        </>
    );
};
