import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Loading } from '../loading/Loading';
import './ImageViewer.css';
import { Image } from './models/Image';
import { State } from './models/State';
import service from './Service';

const ImageViewer = ({ data, token }: { data?: Image[]; token: string | null | undefined }) => {
    const [state, setState] = useState<State>(State.LOADING);
    const navigator = useNavigate();

    const [images, setImages] = useState<Image[]>(data ?? []);

    const [index, setIndex] = useState(0);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [prevEnabled, setPrevEnabled] = useState(false);

    useEffect(() => {
        if (token) {
            (async () => {
                await service
                    .getImages(token)
                    .then((response) => {
                        setImages(response);
                        setNextEnabled(images?.length > 0);
                        setState(State.OK);
                    })
                    .catch(() => {
                        navigator('/notLoaded', { state: token });
                    });
            })();
        }
    }, [images.length, token, navigator]);

    const next = () => {
        if (images?.length !== undefined) {
            let t = index + 1;
            if (t < images.length) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < images.length - 1);
            }
        }
    };

    const previous = () => {
        if (images?.length !== undefined) {
            let t = index - 1;
            if (t >= 0) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < images.length - 1);
            }
        }
    };

    const first = () => {
        if (images?.length !== undefined) {
            let t = 0;
            if (t < images.length) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < images.length - 1);
            }
        }
    };

    const last = () => {
        if (images?.length !== undefined) {
            let t = images.length - 1;
            if (t >= 0) {
                setIndex(t);
                setPrevEnabled(t > 0);
                setNextEnabled(t < images.length - 1);
            }
        }
    };

    return (
        <>
            <div className="d-flex justify-content-between">
                <button disabled={!prevEnabled} onClick={first}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-skip-start-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M4 4a.5.5 0 0 1 1 0v3.248l6.267-3.636c.54-.313 1.232.066 1.232.696v7.384c0 .63-.692 1.01-1.232.697L5 8.753V12a.5.5 0 0 1-1 0z" />
                    </svg>
                </button>
                <button disabled={!prevEnabled} onClick={previous}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-caret-left-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z" />
                    </svg>
                </button>
                {state === State.OK && (
                    <label className="mt-2" style={{ fontSize: 16 }}>
                        Page {index + 1} of {images?.length}
                    </label>
                )}
                <button disabled={!nextEnabled} onClick={next}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-caret-right-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                    </svg>
                </button>
                <button disabled={!nextEnabled} onClick={last}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-skip-end-fill"
                        viewBox="0 0 16 16"
                    >
                        <path d="M12.5 4a.5.5 0 0 0-1 0v3.248L5.233 3.612C4.693 3.3 4 3.678 4 4.308v7.384c0 .63.692 1.01 1.233.697L11.5 8.753V12a.5.5 0 0 0 1 0z" />
                    </svg>
                </button>
            </div>
            {state === State.OK && (
                <div className="container">
                    {images?.length === 0 && <Loading />}
                    {(images?.length ?? 0) > 0 && (
                        <>
                            <div className="container text-center">
                                <Carousel activeIndex={index} indicators={false}>
                                    {images?.map((image) => {
                                        return (
                                            <Carousel.Item key={image.Page}>
                                                <TransformWrapper>
                                                    <TransformComponent>
                                                        <img
                                                            id={'#' + image.Page}
                                                            className="d-block w-100"
                                                            alt={'Page' + image.Page}
                                                            src={image.Content}
                                                        ></img>
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            </Carousel.Item>
                                        );
                                    })}
                                </Carousel>
                            </div>
                        </>
                    )}
                </div>
            )}
            {state === State.LOADING && (
                <div className="container topSpace p-8">
                    <div className="progress progress-medium progress-bar-indeterminate mb-16">
                        <div className="progress-bar"></div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ImageViewer;
