import { RequestForm } from '../../components/request/RequestForm';
import { SplitLayout } from '../../components/shared/SplitLayout';
import { Background } from '../../components/stack/models/Background';
import { Orientation } from '../../components/stack/models/Orientation';
import { Stack } from '../../components/stack/Stack';
import { SupportBox } from '../../components/support/SupportBox';
import UseIsMobile from '../../utils/useIsMobile';

export const RequestFormPage = () => {
    return (
        <>
            {UseIsMobile() && (
                <div className="container">
                    <RequestForm />
                </div>
            )}
            {!UseIsMobile() && (
                <SplitLayout
                    left={<RequestForm />}
                    right={
                        <Stack orientation={Orientation.VERTICAL} background={Background.GREY}>
                            <SupportBox />
                        </Stack>
                    }
                ></SplitLayout>
            )}
        </>
    );
};
