import { forwardRef, ReactNode } from 'react';
import { State } from '../shared/models/State';

// base button, with ref forwarding
type Props = { children: ReactNode; type: 'submit' | 'button'; state: State };
export type Ref = HTMLButtonElement;

export const SpinnerButton = forwardRef<Ref, Props>((props, ref) => {
    return (
        <>
            {props.state === State.SUBMITTING && (
                <button ref={ref} type={props.type} className="btn btn-primary" disabled>
                    {props.children}
                    <span className="ms-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </button>
            )}

            {props.state === State.LOADING && (
                <button ref={ref} type={props.type} className="btn btn-primary" disabled>
                    {props.children}
                </button>
            )}

            {props.state === State.FAILED && (
                <button ref={ref} type={props.type} className="btn btn-primary" disabled>
                    {props.children}
                </button>
            )}

            {props.state === State.UNAUTHORIZED && (
                <button ref={ref} type={props.type} className="btn btn-primary" disabled>
                    {props.children}
                </button>
            )}

            {props.state === State.OK && (
                <button ref={ref} type={props.type} className="btn btn-primary">
                    {props.children}
                </button>
            )}
        </>
    );
});
