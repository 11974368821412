import '@bcg-web/bcg-bootstrap/dist/bcg-bootstrap.min.css';
import { datadogRum } from '@datadog/browser-rum';
import { BrowserRouter as Router } from 'react-router-dom';
import '../services/axiosConfig';
import config from '../utils/config';
import RouteHandler from './RouteHandler';

datadogRum.init({
    applicationId: config.rumApplicationID,
    clientToken: config.rumClientToken,
    site: 'datadoghq.com',
    service: config.rumService,
    env: config.rumEnvironment,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 80,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
        {
            match: (url: string) => url.startsWith(config.proxyAPI),
            propagatorTypes: ['datadog', 'tracecontext'],
        },
    ],
});

function App() {
    return (
        <Router basename="/nrl">
            <RouteHandler />
        </Router>
    );
}

export default App;
